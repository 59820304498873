import {
  ArrowCircleLeftOutlinedIcon,
  Box,
  Button,
  CircularProgress,
  FilterListIcon,
  Grid,
  IconButton,
  KeyboardArrowDownIcon,
  Typography,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as singleSpa from "single-spa";
import "../../assets/css/coincidences.scss";
import { getSearchUser } from "../../features/actions/app-users.actions";
import {
  getElectricitySupplyServiceLike,
  getFrontiers,
  getStates,
} from "../../features/actions/electricity-supply-service.actions";
import { getMagicalParameters } from "../../helpers/helpers";
import { ValueCard } from "../../models/ValueCard";
import {
  instanceOfFrontier,
  instanceOfSearchUserMain,
  instanceOfStates,
} from "../../models/coincidenceState";
import { StateStorage } from "../../models/stateStorage";
import { AppDispatch } from "../../store/store";
import CardElectricitySupply from "./components/CardElectricitySupply";
import CardEstates from "./components/CardEstates";
import CardFrontier from "./components/CardFrontier";

const Coincidences = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoadingData } = useSelector(
    (state: StateStorage) => state.coincidenceState
  );

  useEffect(() => {
    const param = getMagicalParameters();
    const pattern = param.get("pattern");
    const serviceTypeString = param.get("service-type")!;
    const serviceTypeName = serviceTypeString as ValueCard;
    if (serviceTypeName == "Ener. Convencional") {
      dispatch(getElectricitySupplyServiceLike(pattern ?? ""));
      return;
    }
    if (serviceTypeName == "Usuarios") {
      dispatch(
        getSearchUser({
          pattern: pattern ?? "",
        })
      );
      return;
    }
    if (serviceTypeName == "Fronteras") {
      dispatch(getFrontiers(pattern ?? ""));
      return;
    }
    if (serviceTypeName == "Predios") {
      dispatch(getStates(pattern ?? "")); //predios aqui
      return;
    }
    return;
  }, []);

  /* const _getEstateIdCards = () => {
    for (let i = 0; i < data.length; i++) {
      for (let l = 1; l < data.length; l++) {
        let estate_id = data[i].id;
        let f: any = data[l].id;
        if (estate_id === f) {
        }
        console.log("estate_id[0]", estate_id);
        console.log("estate_id[1]", f);
      }
    }
  }; */
  /* console.log(data);

  console.log("_getEstateIdCards", _getEstateIdCards()); */
  const _getCards = () => {
    return data.map((value, index) => {
      if (instanceOfSearchUserMain(value)) {
        return (
          <Box className="Card-search" key={value.id}>
            <Box>
              <Box className="Text-card-main" sx={{ fontWeight: "700" }}>
                {`${value.pii?.names || ""}  ${value.pii?.last_names || ""}`}
              </Box>
              <Box className="Text-card-main">{value.username}</Box>
              <Box className="Text-card-main">
                {value.pii?.phones?.length > 0 ? value.pii.phones[0].phone : ""}
              </Box>
            </Box>
            <Box className="Bar"></Box>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => {
                singleSpa.navigateToUrl("/#/services-account?q=" + value.id);
                return;
              }}
            >
              <Box style={{ fontWeight: "bold", margin: 0 }}>Ver cliente</Box>
            </Button>
          </Box>
        );
      }
      if (instanceOfStates(value)) {
        return <CardEstates key={value.details?.id} states={value} />;
      }
      if (instanceOfFrontier(value)) {
        return <CardFrontier key={value.id} frontier={value} index={index} />;
      }
      return (
        <CardElectricitySupply
          key={value.id}
          electricitySupplyService={value}
          index={0}
        />
      );
    });
  };

  return (
    <Box className="Component-root">
      <Box>
        <IconButton
          sx={{ padding: "1px !important" }}
          onClick={() => singleSpa.navigateToUrl("/#/search-bitoria")}
        >
          <ArrowCircleLeftOutlinedIcon
            className="Icon-back"
            sx={{ stroke: "#ffffff", strokeWidth: 1 }}
          />
        </IconButton>
      </Box>
      <Box>
        <Grid sx={{ flexGrow: 1 }} container>
          <Grid item xs={12} md={10}>
            <Box className="Header-main-coincidences">
              {data.length} Resultado{data.length != 1 && "s"} encontrado
              {data.length != 1 && "s"}
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              disabled
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ maxWidth: "170px" }}
            >
              <Box style={{ fontWeight: "bold", margin: 0 }}>
                <Box sx={{ display: "inline-flex", mt: "4px" }}>
                  <Typography sx={{ mr: "10px" }}>
                    <FilterListIcon />
                  </Typography>
                  <Typography sx={{ mr: "10px", mt: "3px" }}>
                    Filtrar
                  </Typography>
                  <Typography>
                    <KeyboardArrowDownIcon />
                  </Typography>
                </Box>
              </Box>
            </Button>
          </Grid>
        </Grid>
        {isLoadingData ? (
          <CircularProgress />
        ) : (
          <Box className="Cards-container-coincidences">{_getCards()}</Box>
        )}
      </Box>
    </Box>
  );
};

export default Coincidences;
