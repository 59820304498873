import { configureStore } from "@reduxjs/toolkit";
import coincidenceReducer from "../features/slices/coincidence";

export const store = configureStore({
  reducer: {
    coincidenceState: coincidenceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
