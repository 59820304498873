import { Box, NavSidebar, PrivateRoute, ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import Coincidences from "../pages/coincidences/Coincidences";
import { store } from "../store/store";

export default function Routers() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <div style={{ display: "flex", flexDirection: "row", height: "100vh", width: "100vw", overflow: "hidden" }}>
          <PrivateRoute>
            <>
              <NavSidebar />
              <Box className="Container-coincidences">
                <Coincidences />
              </Box>
            </>
          </PrivateRoute>
        </div>
      </ThemeConfig>
    </Provider>
  );
}
