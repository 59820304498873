import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
export type GetSearchUserProps = {
  pattern: string;
};

export const getSearchUser = createAsyncThunk(
  "serviceUser/getSearchUser",
  async (payload: GetSearchUserProps) => {
    const res = await api.get(`/users/app-users/search`, {
      params: { pattern: payload.pattern },
    });
    return res.data.items;
  }
);
