import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

//Obtener estates por estate_id del account manager
export const getAccountManagerEstates = createAsyncThunk(
  //action type string
  "[ACCOUNT MANAGER] Get Account Manager Estates",
  //callback function
  async (estate_id: string, { rejectWithValue }): Promise<any> => {
    const { data, status } = await api.get(`/accounts/estates/${estate_id}/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("No se pudo obtener los datos.");
    return rejectWithValue("estate_id");
  }
);
