import { Box, Button, Grid, Typography, enerbitColors } from "@enerbit/base";
import { navigateToUrl } from "single-spa";
import { States } from "../../../models/states/states";

export type CardElectricitySupplyProps = {
  states: States;
};

const CardElectricitySupply = ({ states }: CardElectricitySupplyProps) => {
  return (
    <Box className="Card-search">
      <Box sx={{ width: "100%" }}>
        <Box>
          <Grid container alignItems={"center"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: enerbitColors.neutral.main,
                  fontWeight: "700",
                }}
              >
                {states.address}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: enerbitColors.neutral.main,
                  fontWeight: "700",
                }}
              >
                {states.city}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: enerbitColors.neutral.main,
                  fontWeight: "700",
                }}
              >
                {states.state}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Button
        variant="contained"
        color="secondary"
        sx={{ mt: "10px" }}
        fullWidth
        onClick={() => {
          navigateToUrl(`/#/states/${states.id}`);
          return;
        }}
      >
        <Box style={{ fontWeight: "bold", margin: 0 }}>Ver predio</Box>
      </Button>
    </Box>
  );
};

export default CardElectricitySupply;
