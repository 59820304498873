import { Typography, enerbitColors } from "@enerbit/base";

export type ItemFrontierProps = {
  title: string;
  description: string;
};
export const ItemFrontier = ({ title, description }: ItemFrontierProps) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: "16px",
          color: enerbitColors.neutral.main,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "16px",
          color: enerbitColors.neutral.main,
          width: "85%",
        }}
      >
        {description}
      </Typography>
    </>
  );
};
