import { Box, Grid, IconButton, KeyboardArrowDownIcon, LoadingButton } from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { navigateToUrl } from "single-spa";
import { getElectricitySupplyService } from "../../../features/actions/electricity-supply-service.actions";
import { Frontier } from "../../../models/frontier/frontier";
import { AppDispatch } from "../../../store/store";
import { ItemFrontier } from "./ItemFrontier";

export type CardFrontierProps = {
  frontier: Frontier;
  index: number;
};

const CardFrontier = ({ frontier, index }: CardFrontierProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);

  useEffect(() => {
    if (shouldNavigate && frontier.electricitySupplyService) {
      navigateToUrl(`/#/electricity-supply-service/attention/${frontier.electricitySupplyService!.id}`);
      setShouldNavigate(false);
    }
  }, [shouldNavigate, frontier.electricitySupplyService]);

  return (
    <Box className="Card-search">
      <Box sx={{ width: "100%" }}>
        <Grid container direction="row" alignItems="flex-start" justifyContent="space-between">
          <Box
            sx={{
              width: "90%",
            }}
          >
            <ItemFrontier title="Frontera" description={frontier.frontier_xm_code ?? "Sin código"} />
            <ItemFrontier title="ID de frontera" description={frontier.id ?? "Sin ID"} />
            <ItemFrontier
              title="Fecha de registro ante XM"
              description={
                frontier.frontier_xm_registered_from
                  ? moment(frontier.frontier_xm_registered_from).utc().format("YYYY-MM-DD")
                  : "Sin Fecha"
              }
            />
          </Box>
          <Box
            sx={{
              width: "10%",
            }}
          >
            <IconButton
              color="primary"
              onClick={() => {
                if (isToggled && !frontier.electricitySupplyService) {
                  dispatch(
                    getElectricitySupplyService({
                      measurement_point_id: frontier.measurement_point_id ?? "",
                      index,
                    })
                  );
                }
                setIsToggled(!isToggled);
              }}
            >
              {isToggled ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Grid>

        {/* <Box className="Bar"></Box>
        {!isToggled && frontier.isLoadingElectricitySupplyService ? (
          <CircularProgress />
        ) : (
          !isToggled &&
          frontier.electricitySupplyService && (
            <ItemFrontier
              title="Dirección"
              description={frontier.electricitySupplyService.estate.address ?? "Sin Dirección"}
            />
          )
        )} */}
        <LoadingButton
          sx={{
            marginTop: "16px",
          }}
          variant="contained"
          color="secondary"
          fullWidth
          disabled={frontier.isLoadingElectricitySupplyService}
          loading={frontier.isLoadingElectricitySupplyService}
          onClick={() => {
            if (frontier.isLoadingElectricitySupplyService) {
              return;
            }
            if (!frontier.electricitySupplyService) {
              setShouldNavigate(true);
              dispatch(
                getElectricitySupplyService({
                  measurement_point_id: frontier.measurement_point_id ?? "",
                  index,
                })
              );
              return;
            }
            navigateToUrl(`/#/electricity-supply-service/attention/${frontier.electricitySupplyService!.id}`);
            return;
          }}
        >
          <Box style={{ fontWeight: "bold", margin: 0 }}>Ver servicio</Box>
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CardFrontier;
