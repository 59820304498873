import { EnerbitPagination } from "@enerbit/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Estate } from "../../models/account-manager/Estate";
import { CoincidenceState, SearchUserMain, instanceOfFrontier } from "../../models/coincidenceState";
import { ElectricitySupplyService } from "../../models/electricity-supply/ElectricitySupplyService";
import { Frontier } from "../../models/frontier/frontier";
import { Meta } from "../../models/redux/meta";
import { StateStorage } from "../../models/stateStorage";
import { States } from "../../models/states/states";
import { getAccountManagerEstates } from "../actions/account-manager.actions";
import { getSearchUser } from "../actions/app-users.actions";
import {
  GetElectricitySupplyServiceProps,
  getElectricitySupplyService,
  getElectricitySupplyServiceLike,
  getFrontiers,
  getStates,
} from "../actions/electricity-supply-service.actions";

const initialState: CoincidenceState = {
  isLoadingData: false,
  data: [],
  estate: {} as Estate,
  isLoadingEstate: false,
  cardAdditionalInformation: [],
  addressError: [],
};
interface AxiosError {
  request?: any;
  message?: string;
  code?: string;
}
export const invoiceSlice = createSlice({
  name: "coincidenceState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSearchUser.pending.type, (state, _action) => {
        state.isLoadingData = true;
        state.data = [];
      })
      .addCase(getSearchUser.fulfilled.type, (state, action: PayloadAction<SearchUserMain[]>) => {
        state.data = action.payload;
        state.isLoadingData = false;
      })
      .addCase(getSearchUser.rejected.type, (state, _action) => {
        state.isLoadingData = false;
      })
      // getElectricitySupplyService
      .addCase(getElectricitySupplyServiceLike.pending.type, (state, _action) => {
        state.isLoadingData = true;
        state.data = [];
      })
      .addCase(
        getElectricitySupplyServiceLike.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<ElectricitySupplyService>>) => {
          state.isLoadingData = false;
          state.data = action.payload.items;
        }
      )
      .addCase(getElectricitySupplyServiceLike.rejected.type, (state, _action) => {
        state.isLoadingData = false;
      })
      // getFrontiers
      .addCase(getFrontiers.pending.type, (state, _action) => {
        state.isLoadingData = true;
        state.data = [];
      })
      .addCase(getFrontiers.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<Frontier>>) => {
        state.isLoadingData = false;
        state.data = action.payload.items;
      })
      .addCase(getFrontiers.rejected.type, (state, _action) => {
        state.isLoadingData = false;
      })
      // getElectricitySupplyService
      .addCase(
        getElectricitySupplyService.pending.type,
        (state, action: PayloadAction<void, string, Meta<GetElectricitySupplyServiceProps>>) => {
          const { index } = action.meta.arg;
          const data = state.data[index];
          if (instanceOfFrontier(data)) {
            data.isLoadingElectricitySupplyService = true;
          }
        }
      )
      .addCase(
        getElectricitySupplyService.fulfilled.type,
        (
          state,
          action: PayloadAction<
            EnerbitPagination<ElectricitySupplyService>,
            string,
            Meta<GetElectricitySupplyServiceProps>
          >
        ) => {
          const { index } = action.meta.arg;
          const data = state.data[index];
          if (instanceOfFrontier(data)) {
            if (action.payload.items.length > 0) {
              data.electricitySupplyService = action.payload.items[0];
            }
            data.isLoadingElectricitySupplyService = false;
          }
        }
      )
      .addCase(
        getElectricitySupplyService.rejected.type,
        (state, action: PayloadAction<void, string, Meta<GetElectricitySupplyServiceProps>>) => {
          const { index } = action.meta.arg;
          const data = state.data[index];
          if (instanceOfFrontier(data)) {
            data.isLoadingElectricitySupplyService = false;
          }
        }
      ) // getAccountManagerEstates
      .addCase(getAccountManagerEstates.pending.type, (state, action) => {
        state.isLoadingEstate = true;
        state.estate = {} as Estate;
      })
      .addCase(getAccountManagerEstates.fulfilled.type, (state, action: PayloadAction<Estate>) => {
        state.isLoadingEstate = false;
        state.estate = action.payload;
        state.cardAdditionalInformation.push(
          action.payload
        ); /* Esta linea hace refencia a el filtro que usamos para obtener la direccion de cada servicio con un reducer llamado getCardAdditionalInformation */
      })
      .addCase(getAccountManagerEstates.rejected.type, (state, action: PayloadAction<void, string, Meta<string>>) => {
        state.isLoadingEstate = false;
        state.addressError.push({ estate_id: action.meta.arg, error: "Ocurrió un error en el servidor." });
      })
      .addCase(getStates.pending.type, (state, _action) => {
        state.isLoadingData = true;
        state.data = [];
      })
      .addCase(getStates.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<States>>) => {
        state.isLoadingData = false;
        state.data = action.payload.items;
      })
      .addCase(getStates.rejected.type, (state, _action) => {
        state.isLoadingData = false;
      });
  },
});

export const isLoadingUser = (state: StateStorage) => state.coincidenceState.isLoadingData;
export const searchUser = (state: StateStorage) => state.coincidenceState.data;

export default invoiceSlice.reducer;
