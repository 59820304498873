import {
  Box,
  Button,
  CircularProgress,
  CustomAlert,
  Grid,
  IconButton,
  KeyboardArrowDownIcon,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; // TODO: Agregar al base
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"; // TODO: Agregar al base
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigateToUrl } from "single-spa";
import { getAccountManagerEstates } from "../../../features/actions/account-manager.actions";
import { ElectricitySupplyService } from "../../../models/electricity-supply/ElectricitySupplyService";
import { StateStorage } from "../../../models/stateStorage";
import { AppDispatch } from "../../../store/store";

export type CardElectricitySupplyProps = {
  electricitySupplyService: ElectricitySupplyService;
  index: any;
};

const CardElectricitySupply = ({ electricitySupplyService, index }: CardElectricitySupplyProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { addressError, isLoadingEstate, cardAdditionalInformation } = useSelector(
    (state: StateStorage) => state.coincidenceState
  );
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const getEstates = () => {
    dispatch(getAccountManagerEstates(electricitySupplyService.estate_id));
  };
  const estatesInAdditionalInformation = cardAdditionalInformation.find(
    (estates) => estates.id === electricitySupplyService.estate_id
  );
  const addressCardError = addressError.find(
    (estate) => estate.estate_id === electricitySupplyService.estate_id
  )?.error;
  return (
    <Box className="Card-search">
      <Box sx={{ width: "100%" }}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={10}>
            <Grid container spacing={2} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: enerbitColors.neutral.main,
                    pt: "8px",
                  }}
                >
                  NIU
                </Typography>
              </Grid>
              <Grid paddingLeft={"16px"} paddingTop={"0"}>
                <Typography
                  sx={{
                    color: enerbitColors.neutral.main,
                  }}
                  className="Text-card-box"
                >
                  {electricitySupplyService.creg_subscriber.niu}
                </Typography>
              </Grid>
            </Grid>
            {electricitySupplyService.creg_subscriber.distribution_operator_subscriber_id !== "" && (
              <Grid container spacing={2} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: enerbitColors.neutral.main,
                    }}
                  >
                    Código interno
                  </Typography>
                </Grid>
                <Grid paddingLeft={"16px"} paddingTop={"0"}>
                  <Typography
                    sx={{
                      color: enerbitColors.neutral.main,
                    }}
                    className="Text-card-box"
                  >
                    {electricitySupplyService.creg_subscriber.distribution_operator_subscriber_id}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", alignItems: "center", alignContent: "center", margin: "0 0rem 0 0" }}
          >
            <IconButton
              color="primary"
              sx={{ borderRadius: "10px !important" }}
              onClick={() => {
                if (cardAdditionalInformation.find((estates) => estates.id === electricitySupplyService.estate_id)) {
                  return setIsToggled(!isToggled);
                }
                setIsToggled(!isToggled);
                getEstates();
              }}
            >
              {isToggled ? (
                <div>
                  <LocationOnOutlinedIcon />
                  <KeyboardArrowDownIcon />
                </div>
              ) : (
                <div>
                  <LocationOnOutlinedIcon />
                  <KeyboardArrowUpIcon />
                </div>
              )}
            </IconButton>
          </Grid>
        </Grid>
        <Box className="Bar"></Box>
        {isToggled && (
          <Grid alignItems="flex-start">
            <Grid item xs={12}>
              {isLoadingEstate &&
              !estatesInAdditionalInformation &&
              !addressError.find((estate) => estate.estate_id === electricitySupplyService.estate_id)?.error ? (
                <Grid container justifyContent={"center"}>
                  <CircularProgress />
                </Grid>
              ) : estatesInAdditionalInformation ? (
                <>
                  <Grid display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: enerbitColors.neutral.main,
                        }}
                        className="Title-card-box"
                      >
                        {estatesInAdditionalInformation?.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: enerbitColors.neutral.main,
                        }}
                      >
                        Ciudad
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: enerbitColors.neutral.main,
                        }}
                        className="Text-card-box"
                      >
                        {estatesInAdditionalInformation?.city}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                addressCardError && <CustomAlert severity="error" text={addressCardError} onClose={() => null} />
              )}
            </Grid>
          </Grid>
        )}
      </Box>

      <Button
        variant="contained"
        color="secondary"
        sx={{ mt: "10px" }}
        fullWidth
        onClick={() => {
          navigateToUrl(`/#/electricity-supply-service/attention/${electricitySupplyService.id}`);
          return;
        }}
      >
        <Box style={{ fontWeight: "bold", margin: 0 }}>Ver servicio</Box>
      </Button>
    </Box>
  );
};

export default CardElectricitySupply;
