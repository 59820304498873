import { EnerbitPagination, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ElectricitySupplyService } from "../../models/electricity-supply/ElectricitySupplyService";
import { Frontier } from "../../models/frontier/frontier";
import { States } from "../../models/states/states";

export const getElectricitySupplyServiceLike = createAsyncThunk(
  "[ELECTRICITY SUPPLY SERVICE] Get Electricity Supply Service Like",
  async (pattern: string): Promise<EnerbitPagination<ElectricitySupplyService>> => {
    const res = await api.get(`/electricity-supply-service/manager/electricity-supply-services/like`, {
      params: { pattern },
    });
    return res.data;
  }
);

export type GetElectricitySupplyServiceProps = {
  measurement_point_id: string;
  index: number;
};

export const getElectricitySupplyService = createAsyncThunk(
  "[ELECTRICITY SUPPLY SERVICE] Get Electricity Supply Service",
  async (params: GetElectricitySupplyServiceProps): Promise<EnerbitPagination<ElectricitySupplyService>> => {
    const res = await api.get(`/electricity-supply-service/manager/electricity-supply-services/`, {
      params,
    });
    return res.data;
  }
);

export const getFrontiers = createAsyncThunk(
  "[ELECTRICITY SUPPLY SERVICE] Get Frontiers",
  async (pattern: string): Promise<EnerbitPagination<Frontier>> => {
    const res = await api.get(`electricity-supply-service/frontier/frontiers/like`, {
      params: {
        pattern,
      },
    });
    return res.data;
  }
);

export const getStates = createAsyncThunk(
  "[ELECTRICITY SUPPLY SERVICE] Get States",
  async (pattern: string): Promise<EnerbitPagination<States>> => {
    const res = await api.get(`accounts/estate`, {
      params: {
        address_like: pattern,
      },
    });
    return res.data;
  }
);
