import { Estate } from "./account-manager/Estate";
import { ElectricitySupplyService } from "./electricity-supply/ElectricitySupplyService";
import { Frontier } from "./frontier/frontier";
import { States } from "./states/states";

export interface CoincidenceState {
  isLoadingData: boolean;
  data: SearchUserMain[] | ElectricitySupplyService[] | Frontier[] | States[];
  estate?: Estate;
  isLoadingEstate: Boolean;
  cardAdditionalInformation: Estate[];
  addressError: {
    estate_id: string;
    error: string;
  }[];
}
export interface ArrayExample {}

export interface Pii {
  names: string;
  last_names: string;
  legal_id_type: string;
  legal_id_code: string;
  verification_code: string;
  phones: Phones[];
  email: string;
  billing_estate_id?: any;
  id: string;
  created_at: Date;
  app_user_id: string;
}

export interface Phones {
  created_at: Date;
  id: string;
  is_verified: boolean;
  personal_information_id: string;
  phone: string;
  priority: number;
  updated_at?: any;
}

export type SearchUserMain = {
  username: string;
  loggable: boolean;
  id: string;
  created_at: Date;
  hashed_password: string;
  is_first_time: boolean;
  pii: Pii;
};

export function instanceOfSearchUserMain(object: any): object is SearchUserMain {
  return "pii" in object || "username" in object;
}

export function instanceOfFrontier(object: any): object is Frontier {
  return "frontier_xm_code" in object || "frontier_xm_registered_from" in object || "lead_id" in object;
}

export function instanceOfStates(object: any): object is States {
  return "address" in object;
}

export interface DataExistsUser {
  email: string;
  role: string;
  idServiceAccount?: string;
}

export interface ParamSearchUser {
  legal_id_code?: string;
  username?: string;
}
