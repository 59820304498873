import Routers from "./router/Routers";
import { initEnviroment, onBeforeUnload } from "@enerbit/base"; 

onBeforeUnload();

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL
})

export default function Root() {  
  return  <Routers />  
}
